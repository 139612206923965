import React, { useEffect, useState } from "react";
import MainPage from "../main-page";
import useKioskStateContext from "../../contexts/kiosk-state-context";
import { analyzeItemsForItemTypeReport } from "../../utils/item-type-report/itemTypeReportHelpers";
import SubmissionModal from "./features/submission-modal";
import useUserLocationContext from "../../../../context/user-location-context";
import { getLocationWithDetector } from "../../utils/location-detector-mapper";
import { OPTION_TEMPLATE_OPTIONS } from "../../data/constants";
import useKioskConfigContext from "../../contexts/kiosk-config-context";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { naturalSort } from "../../../../common/Utilities";

export const CheckoutTable = () => {
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const xemelgoClient = useXemelgoClient();

  const { userLocation } = useUserLocationContext();
  const { sidePanelAttributes, readerLocationOptionCategory, locationOptionCategory } = useKioskConfigContext();
  const {
    itemByTag,
    setItemTypeReports,
    setOptionTemplateMap,
    setLocationOptions,
    setReaderOptions,
    setLoading,
    itemTypeReports,
    resetState
  } = useKioskStateContext();

  const [locationClient] = useState(xemelgoClient.getLocationClient());
  const [detectorClient] = useState(xemelgoClient.getDetectorClient());
  const [userClient] = useState(xemelgoClientAppSync.getUserClient());

  useEffect(() => {
    fetchData();

    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (!itemByTag) {
      return;
    }
    const reports = analyzeItemsForItemTypeReport(Object.values(itemByTag));
    setItemTypeReports(reports);
  }, [itemByTag]);

  const fetchData = async () => {
    setLoading(true);
    const newLocationTree = locationClient.getLocationTree(["id", "name", "identifier"]);
    const newDetectorsPromise = detectorClient.listDetectors();
    const promises = [newLocationTree, newDetectorsPromise];

    sidePanelAttributes.forEach((attribute) => {
      if (attribute.optionTemplate) {
        promises.push(getOptionsFromOptionsTemplate(attribute.optionTemplate));
      }
    });

    const [locationTree = {}, detectors = [], ...optionsResponses] = await Promise.all(promises);
    const { locationOptionsMap, readerLocationOptionsMap } = getLocationWithDetector(
      locationTree,
      userLocation,
      detectors
    );
    const newOptionsTemplateMap = optionsResponses.reduce((accumulator, response) => {
      const { id, options } = response;
      accumulator[id] = options;
      return accumulator;
    }, {});

    setOptionTemplateMap(newOptionsTemplateMap);

    const newLocationOptions = Object.values(locationOptionsMap).filter((location) => {
      return location.category === locationOptionCategory;
    });
    const newReaderOptions = Object.values(readerLocationOptionsMap).filter((reader) => {
      return reader.category === readerLocationOptionCategory;
    });

    setLocationOptions(naturalSort(newLocationOptions, "label"));
    setReaderOptions(naturalSort(newReaderOptions, "label"));

    setLoading(false);
  };

  const getOptionsFromOptionsTemplate = async (id) => {
    switch (id) {
      case OPTION_TEMPLATE_OPTIONS.USERS:
        const queryUsersResponse = await userClient.queryUsers();
        const { queryUsers = {} } = queryUsersResponse;
        const { users = [] } = queryUsers;
        const userOptions = users.map((user) => {
          const formattedName = `${user.firstName || ""} ${user.lastName || ""}`;
          return {
            id: user.id,
            value: formattedName,
            label: formattedName
          };
        });
        return {
          id,
          options: userOptions
        };
      default:
        return {
          id,
          options: []
        };
    }
  };

  return (
    <>
      <MainPage
        onClearReport={() => {
          setItemTypeReports([]);
        }}
        onClearReportRow={(id) => {
          const reportIndex = itemTypeReports.findIndex((report) => {
            return report.id === id;
          });

          const newReports = [...itemTypeReports];
          newReports.splice(reportIndex, 1);
          setItemTypeReports(newReports);
        }}
      />
      <SubmissionModal />
    </>
  );
};
