import { XemelgoService } from "../../../../../../services/XemelgoService";
import { LOCATION_ROLE_ACTION, PICKLIST_STATUS_MAP, STAGE_TO_SHOWN_ITEM_STATUSES } from "../../data/constants";

export const createItemTypeToReportMap = async (selectedTransferOrders, additionalQueryAttributes, stage) => {
  const transferOrderClient = XemelgoService.getClient().getTransferClient();

  const transferOrderIds = new Set();

  selectedTransferOrders.forEach((transferOrder) => {
    transferOrderIds.add(transferOrder.id);

    transferOrder.contains.forEach((childTransferOrder) => {
      transferOrderIds.add(childTransferOrder.id);
    });
  });

  const transferOrders = await transferOrderClient.getTransferOrdersByIds(transferOrderIds, additionalQueryAttributes);

  const itemTypeToReport = transferOrders.reduce((acc, transferOrder) => {
    transferOrder.hasItemsEntry.forEach((itemEntry) => {
      const {
        hasTransferItemState = [],
        ofItemType = [],
        quantity_total: quantityTotal = 0,
        quantity_staged: quantityStaged = 0,
        quantity_in_progress: quantityInProgress = 0,
        quantity_received: quantityReceived = 0,
        quantity_verified: quantityVerified = 0
      } = itemEntry;
      const stageToCounts = {
        [LOCATION_ROLE_ACTION.STAGE]: quantityStaged + quantityInProgress + quantityReceived + quantityVerified,
        [LOCATION_ROLE_ACTION.SHIP]: quantityInProgress + quantityReceived + quantityVerified,
        [LOCATION_ROLE_ACTION.RECEIVE]: quantityReceived + quantityVerified,
        [LOCATION_ROLE_ACTION.VERIFY]: quantityVerified
      };

      const itemType = ofItemType[0] || {};

      const report = acc[itemType.id] || {
        ...itemType,
        epcs: [],
        quantityTotal: 0,
        quantityScanned: 0,
        status: PICKLIST_STATUS_MAP.notScanned,
        epcToItemMap: {},
        scannedEpcSet: new Set()
      };

      report.quantityTotal += quantityTotal - stageToCounts[stage];

      hasTransferItemState.forEach((itemState) => {
        const { ofItem: items = [], transfer_status: transferStatus } = itemState;

        if (STAGE_TO_SHOWN_ITEM_STATUSES[stage].includes(transferStatus)) {
          items.forEach((item) => {
            const { vid } = item.hasSensorProfile[0];

            report.epcToItemMap[vid] = {
              ...item,
              vid,
              scanned: false,
              isAssociatedWithOrder: true
            };
          });
        }
      });

      acc[itemType.id] = report;
    });

    return acc;
  }, {});

  return { itemTypeToReport, transferOrders };
};
