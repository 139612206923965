import xemelgoStyle from "../../../styles/variable";

export const TITLE = "Goods Receipt";

export const TABLE_COLUMN_SIZE_MAP = {
  xsmall: 0.5,
  small: 2,
  medium: 5,
  large: 8,
  default: 1
};

export const ROW_COUNT_ATTRIBUTE = "rowCount";

export const CLEAR_BUTTON_ID = "clearButton";

export const CLEAR_BUTTON_SECTION = {
  attributes: [
    {
      id: CLEAR_BUTTON_ID
    }
  ]
};

export const ACTION_OPTIONS_MAP = {
  CHECK_OUT: "check-out",
  ADD: "add",
  MOVE: "move",
  CONSUME: "consume",
  UNCONSUME: "unconsume",
  PICKLIST_VERIFICATION: "picklistVerification"
};

export const OPTION_TEMPLATE_OPTIONS = {
  USERS: "users"
};

export const QUERY_PAGE = 50;

export const READ_STATUS_OPTIONS = {
  NOT_READING: {
    id: "notReading",
    buttonLabel: "Start Scan",
    buttonIsDisabled: false,
    statusMessage: ""
  },
  STARTING: {
    id: "starting",
    buttonLabel: "Starting Scan",
    buttonIsDisabled: true,
    statusMessage: "Starting...",
    statusMessageColor: xemelgoStyle.theme.APP_BLUE
  },
  IN_PROGRESS: {
    id: "inProgress",
    buttonLabel: "Pause Scan",
    buttonIsDisabled: false,
    statusMessage: "Reading In Progress...",
    statusMessageColor: xemelgoStyle.theme.STATUS_ORANGE
  },
  PAUSING: {
    id: "pausing",
    buttonLabel: "Pausing Scan",
    buttonIsDisabled: true,
    statusMessage: "Pausing...",
    statusMessageColor: xemelgoStyle.theme.APP_BLUE
  },
  PAUSED: {
    id: "paused",
    buttonLabel: "Resume Scan",
    buttonIsDisabled: false,
    statusMessage: "Reading Paused",
    statusMessageColor: xemelgoStyle.theme.STATUS_RED
  },
  STOPPING: {
    id: "stopping",
    buttonLabel: "Stopping Scan",
    buttonIsDisabled: true,
    statusMessage: "",
    statusMessageColor: xemelgoStyle.theme.APP_BLUE
  }
};
