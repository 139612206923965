import React, { useRef, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import Style from "./AutoSizeTextArea.module.css";

const numberOnlyRegex = /^-?\d*[.,]?\d{0,2}$/;

const AutoSizeTextArea = (props) => {
  const {
    disableTabFocus,
    autoFocus,
    minRows,
    maxRows,
    value,
    onChangeText,
    newLineAllowed,
    placeholder,
    disabled,
    readOnly,
    fontSize,
    fontWeight,
    backgroundColor,
    onBlur,
    onFocus,
    numberOnly,
    error,
    errorMessage,
    textareaClassName,
    readonlyClassName,
    onKeyDown
  } = props;

  const textAreaRef = useRef();
  const textRef = useRef();

  const [isFocused, setIsFocused] = useState(false);

  const onChange = (event) => {
    if (!numberOnly || numberOnlyRegex.test(value === "" ? event.target.value.trim() : event.target.value)) {
      onChangeText(value === "" ? event.target.value.trim() : event.target.value); // when value is equal to empty string, we force to display space ' ' to get rid of textarea align baseline bug, so we need to trim one space character
    }
  };

  useEffect(() => {
    if (textRef.current && readOnly) {
      textRef.current.style["-webkit-line-clamp"] = maxRows;
    }
  }, [readOnly, maxRows, textRef.current]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.rows = minRows; // reset number of rows in textarea
      const currentRows = ~~(
        textAreaRef.current.scrollHeight / parseFloat(window.getComputedStyle(textAreaRef.current)["line-height"])
      ); // calculating number of rows needed by using textarea hight and css line-height property
      textAreaRef.current.scrollTop = isFocused ? textAreaRef.current.scrollHeight : 0; // set scrolling to the very bottom
      textAreaRef.current.scrollLeft = isFocused ? textAreaRef.current.scrollWidth : 0;
      textAreaRef.current.rows = currentRows < maxRows ? currentRows : maxRows;
    }
  }, [value, minRows, maxRows, isFocused, textAreaRef.current, readOnly]);

  // using ref to focus because things like modal will take the focus
  useEffect(() => {
    if (textAreaRef.current && autoFocus) {
      textAreaRef.current.focus();
    }
  }, [autoFocus]);

  // using ref to place caret at the end when user tab into the textarea
  useEffect(() => {
    const handleFocusEnd = () => {
      const textArea = textAreaRef.current;
      if (textArea) {
        const text = textArea.value;
        textArea.setSelectionRange(text.length, text.length);
      }
    };

    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.addEventListener("focus", handleFocusEnd);
    }

    return () => {
      if (textArea) {
        textArea.removeEventListener("focus", handleFocusEnd);
      }
    };
  }, []);

  if (readOnly) {
    return (
      <p
        ref={textRef}
        className={`${Style.block_with_text} ${readonlyClassName}`}
        style={{ fontSize, fontWeight, backgroundColor }}
      >
        {/* {numberOnly ? (numberOnlyRegex.test(value) ? value : (value * 1).toFixed(2)) : value} */}
        {numberOnly ? (numberOnlyRegex.test(value) ? value : value * 1 ? (value * 1).toFixed(2) : value) : value}
      </p>
    );
  }

  return (
    <>
      <textarea
        tabIndex={disableTabFocus ? -1 : 0}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus(event);
        }}
        onBlur={(event) => {
          onChangeText(value?.toString()?.trim());
          setIsFocused(false);
          onBlur(event);
        }}
        disabled={disabled}
        ref={textAreaRef}
        rows={minRows}
        className={`${Style.textarea} ${maxRows === 1 && Style.no_wrap} ${error && Style.error} ${textareaClassName}`}
        style={{ fontSize, fontWeight, backgroundColor }}
        onKeyDown={(event) => {
          onKeyDown(event);
          if (!newLineAllowed && event.key === "Enter") {
            event.preventDefault();
          }
        }}
        wrap="soft"
        onChange={onChange}
        placeholder={placeholder}
        value={
          value
            ? numberOnly
              ? numberOnlyRegex.test(value)
                ? value
                : (value * 1).toFixed(2)
              : value
            : placeholder
            ? ""
            : " "
        } // empty string is to fix the align baseline bug. It needs to be not empty in order to align baseline properly
      />
      {error && errorMessage && <p className={Style.error_message}>{errorMessage}</p>}
    </>
  );
};

AutoSizeTextArea.defaultProps = {
  autoFocus: false,
  minRows: 1,
  maxRows: 1,
  value: "",
  placeholder: "",
  onChangeText: () => {},
  onBlur: () => {},
  onFocus: () => {},
  newLineAllowed: false,
  disabled: false,
  readOnly: false,
  numberOnly: false,
  error: false,
  errorMessage: "",
  textareaClassName: "",
  readonlyClassName: "",
  onKeyDown: () => {}
};

AutoSizeTextArea.propTypes = {
  autoFocus: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeText: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  newLineAllowed: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  numberOnly: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  textareaClassName: PropTypes.string,
  readonlyClassName: PropTypes.string,
  onKeyDown: PropTypes.func
};

export default AutoSizeTextArea;
