import React, { useContext, useMemo } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import xemelgoStyle from "../../../../styles/variable";
import { READ_MODE_OPTIONS } from "../../../../hooks/use-mounted-reader/data/constants";
import {
  DEFAULT_EPC_TABLE_HEADERS,
  DEFAULT_STAGE_OPTIONS,
  DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS
} from "../../features/picklist-verification/data/constants";
import { ACTION_OPTIONS_MAP } from "../../data/constants";
import { AddPageInputTypeMap } from "../../../../data/constants";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";

const TITLE = "title";
const ACTIONS_MAP = "actionsMap";
const TABLE_SECTIONS = "tableSections";
const TOP_PANEL_ATTRIBUTES = "topPanelAttributes";
const SIDE_PANEL_ATTRIBUTES = "sidePanelAttributes";
const API_URL = "apiUrl";
const EDGE_API_URL = "edgeApiUrl";
const ADDITIONAL_QUERY_ATTRIBUTES = "additionalQueryAttributes";
const ITEM_TYPE_QUERY_ATTRIBUTES = "itemTypeQueryAttributes";
const REPORT = "report";
const QUERY_FREQUENCY_IN_SECONDS = "queryFrequencyInSeconds";
const UPC_NUMBER_OF_CHARACTER_TO_TRIM = "upcNumberOfCharactersToTrim";
const INACTIVITY_THRESHOLD_IN_MINUTES = "inactivityThresholdInMinutes";
const READER_POWER_TO_SET = "readerPowerToSet";
const HAS_SGTIN_TAGS = "hasSgtinTags";
const ENABLED_SIMPLE_TAG_GROUPING = "enabledSimpleTagGrouping";
const ROW_BACKGROUND_COLOR = "rowBackgroundColor";
const UPDATE_READER_STATE_ON_CLEAR = "updateReaderStateOnClear";
const TABLE_TEXT_STYLE = "tableTextStyle";
const ITEM_CLASS_FILTERS = "itemClassFilters";
const DEFAULT_LOCATION_IDENTIFIER_FILTER_BY = "defaultLocationIdentifierFilterBy";
const LOCATION_OPTION_CATEGORY = "locationOptionCategory";
const READER_LOCATION_OPTION_CATEGORY = "readerLocationOptionCategory";
const READ_MODE = "readMode";
const STAGE_OPTIONS = "stageOptions";
const UNEXPECTED_ITEMS_TABLE_HEADERS = "unexpectedScanTableHeaders";
const USE_MISSING_ITEM_MODAL = "useMissingItemModal";
const DISABLE_INGESTION = "disableIngestion";
const START_STOP_READER = "startStopReader";
const AUTO_START_SCANNING = "autoStartScanning";
const TRANSFER_ORDER_DISPLAY_NAME = "transferOrderDisplayName";

const KioskConfigContext = React.createContext();

const SOLUTION_ID = "kiosk";

const initialState = {
  [ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION]: {
    tableSections: [
      {
        attributes: [
          {
            id: "rowCount"
          }
        ],
        label: "#",
        size: "xsmall"
      },
      {
        attributes: [
          {
            id: "image_path",
            label: "",
            type: "image"
          }
        ],
        label: "Item Photo"
      },
      {
        attributes: [
          {
            id: "name"
          },
          {},
          {
            id: "showEpcsButton"
          }
        ],
        label: "Item Description",
        size: "small"
      },
      {
        attributes: [
          {
            id: "quantityTotal"
          }
        ],
        label: "Expected"
      },
      {
        attributes: [
          {
            id: "quantityScanned"
          }
        ],
        label: "Scanned"
      },
      {
        attributes: [
          {
            id: "status"
          }
        ],
        label: "Status"
      }
    ],
    topPanelAttributes: [
      {
        id: "stage",
        label: "Stage:",
        isReadOnly: true
      },
      {
        id: "transferOrdersString",
        label: "Tracking #:",
        isReadOnly: true
      },
      {
        id: "readerLocation",
        label: "Read Point:",
        type: AddPageInputTypeMap.SEARCH_DROP_DOWN,
        isRequired: true
      }
    ]
  },
  title: "Kiosk",
  actionsMap: {
    "check-out": {
      id: "check-out",
      label: "Start Checking Out"
    }
  },
  tableSections: [
    {
      attributes: [
        {
          id: "rowCount",
          label: ""
        }
      ],
      label: "#"
    },
    {
      attributes: [
        {
          id: "identifier",
          label: ""
        }
      ],
      label: "UPC"
    },
    {
      attributes: [
        {
          id: "qty"
        }
      ],
      label: "Qty"
    }
  ],
  topPanelAttributes: [
    {
      id: "location",
      label: "Location:",
      type: "dropdownWithOptionsFromAPI",
      isRequired: true
    },
    {
      id: "readerLocation",
      label: "Read Point:",
      type: "dropdownWithOptionsFromAPI",
      isRequired: true
    }
  ],
  sidePanelAttributes: [],
  defaultLocationIdentifierFilterBy: "",
  apiUrl: "https://zcb5qribob.execute-api.us-west-2.amazonaws.com/dev",
  edgeApiUrl: "https://pgsjd2kyk1.execute-api.us-west-2.amazonaws.com/v1",
  report: {
    additionalSubmitAttributesMap: {},
    customSubmitActionsMap: {
      endTrackingSession: false,
      consumeItem: true,
      flipHasExitState: true
    }
  },
  queryFrequencyInSeconds: 3,
  upcNumberOfCharactersToTrim: 2,
  inactivityThresholdInMinutes: 30,
  readerPowerToSet: 25,
  hasSgtinTags: false,
  enableSimpleTagGrouping: false,
  rowBackgroundColor: "transparent",
  updateReaderStateOnClear: true,
  tableTextStyle: {
    fontFamily: `"Avenir", sans-serif`,
    fontWeight: 700,
    fontSize: 15,
    color: xemelgoStyle.theme.TEXT_PRIMARY,
    backgroundColor: xemelgoStyle.theme.APP_WHITE
  },
  itemClassFilters: [],
  locationOptionCategory: "Department",
  readerLocationOptionCategory: "Department",
  readMode: READ_MODE_OPTIONS.TAGS_TABLE,
  stageOptions: DEFAULT_STAGE_OPTIONS,
  epcTableHeaders: DEFAULT_EPC_TABLE_HEADERS,
  unexpectedScanTableHeaders: DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS,
  useMissingItemModal: true,
  additionalQueryAttributes: {
    itemType: ["image_path"],
    transferOrder: [],
    item: []
  },
  itemTypeQueryAttributes: [],
  disableIngestion: false,
  startStopReader: false,
  autoStartScanning: true,
  transferOrderDisplayName: "Tracking #"
};

export const useKioskConfigContext = () => {
  return useContext(KioskConfigContext);
};

export const KioskConfigContextProvider = ({ children }) => {
  const { actionId } = useKioskSearchParams();

  const kioskConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const configValues = useMemo(() => {
    const { config = {} } = kioskConfigProvider;
    const { actionsMap: newActionsMap = {} } = config;
    const actionConfig = newActionsMap[actionId] || {};

    const getConfigValue = (key) => {
      const { actionType } = actionConfig;
      return actionConfig[key] ?? initialState[actionType]?.[key] ?? config[key] ?? initialState[key];
    };

    const itemTypeQueryAttributes = getConfigValue(ITEM_TYPE_QUERY_ATTRIBUTES);
    const additionalQueryAttributes = getConfigValue(ADDITIONAL_QUERY_ATTRIBUTES);
    additionalQueryAttributes.itemType.push(...itemTypeQueryAttributes);

    const queryFrequencyInSeconds = getConfigValue(QUERY_FREQUENCY_IN_SECONDS);

    return {
      title: getConfigValue(TITLE),
      actionsMap: getConfigValue(ACTIONS_MAP),
      tableSections: getConfigValue(TABLE_SECTIONS),
      topPanelAttributes: getConfigValue(TOP_PANEL_ATTRIBUTES),
      sidePanelAttributes: getConfigValue(SIDE_PANEL_ATTRIBUTES),
      edgeApiUrl: getConfigValue(EDGE_API_URL),
      upcNumberOfCharactersToTrim: getConfigValue(UPC_NUMBER_OF_CHARACTER_TO_TRIM),
      inactivityThresholdInMinutes: getConfigValue(INACTIVITY_THRESHOLD_IN_MINUTES),
      hasSgtinTags: getConfigValue(HAS_SGTIN_TAGS),
      enableSimpleTagGrouping: getConfigValue(ENABLED_SIMPLE_TAG_GROUPING),
      rowBackgroundColor: getConfigValue(ROW_BACKGROUND_COLOR),
      updateReaderStateOnClear: getConfigValue(UPDATE_READER_STATE_ON_CLEAR),
      tableTextStyle: getConfigValue(TABLE_TEXT_STYLE),
      itemClassFilters: getConfigValue(ITEM_CLASS_FILTERS),
      reportConfig: getConfigValue(REPORT),
      defaultLocationIdentifierFilterBy: getConfigValue(DEFAULT_LOCATION_IDENTIFIER_FILTER_BY),
      locationOptionCategory: getConfigValue(LOCATION_OPTION_CATEGORY),
      readerLocationOptionCategory: getConfigValue(READER_LOCATION_OPTION_CATEGORY),
      readMode: getConfigValue(READ_MODE),
      stageOptions: getConfigValue(STAGE_OPTIONS),
      unexpectedScanTableHeaders: getConfigValue(UNEXPECTED_ITEMS_TABLE_HEADERS),
      useMissingItemModal: getConfigValue(USE_MISSING_ITEM_MODAL),
      additionalQueryAttributes: getConfigValue(ADDITIONAL_QUERY_ATTRIBUTES),
      disableIngestion: getConfigValue(DISABLE_INGESTION),
      startStopReader: getConfigValue(START_STOP_READER),
      queryFrequencyInSeconds,
      readSettings: {
        apiUrl: getConfigValue(API_URL),
        readerPower: getConfigValue(READER_POWER_TO_SET),
        queryFrequencyInSeconds
      },
      autoStartScanning: getConfigValue(AUTO_START_SCANNING),
      transferOrderDisplayName: getConfigValue(TRANSFER_ORDER_DISPLAY_NAME)
    };
  }, [kioskConfigProvider, actionId]);

  return <KioskConfigContext.Provider value={configValues}>{children}</KioskConfigContext.Provider>;
};
