import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../../../../../components/modal";
import useKioskConfigContext from "../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../contexts/kiosk-state-context";
import Style from "./SubmissionModal.module.css";
import { STATUS_OPTIONS } from "../../../../../../components/status-popup-component/data/constants";
import { KIOSK, KIOSK_STEPS } from "../../../../../../constants/mixpanel-constant/kiosk";
import useMixpanelContext from "../../../../../../context/mixpanel-context";
import { getDetectorEventPayloads } from "../../utils/get-detector-event-payloads";
import Status from "../../../../../../components/paginated-list-table-with-types/components/status";
import xemelgoStyle from "../../../../../../styles/variable";
import { STAGE_TO_STATUS_MAP } from "../../data/constants";
import { XemelgoService } from "../../../../../../services/XemelgoService";
import useKioskSearchParams from "../../../../hooks/use-kiosk-search-params";

export const SubmissionModal = ({ onClose, transferOrders, itemTypeToReport }) => {
  const { actionId } = useKioskSearchParams();
  const { sendMixPanelEvent } = useMixpanelContext();
  const { submitStatus, setSubmitStatus, setStatusMessage, panelValues, resetState } = useKioskStateContext();
  const { actionsMap, transferOrderDisplayName } = useKioskConfigContext();

  const transferOrderEvents = useMemo(() => {
    return getDetectorEventPayloads(transferOrders, itemTypeToReport, panelValues.stage);
  }, [transferOrders, itemTypeToReport, panelValues.stage]);

  const onConfirm = async () => {
    const publishClient = XemelgoService.getClient().getPublishClient();

    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage(`Submitting ${actionsMap[actionId]?.label}`);

    const detectorId = panelValues.readerLocation?.detectorId;

    try {
      for (const transferOrderEvent of transferOrderEvents) {
        const { id, itemTypeEvents } = transferOrderEvent;

        for (const itemTypeEvent of itemTypeEvents) {
          const { sensorProfileIds, itemsEntryId } = itemTypeEvent;

          const context = {
            actions: {
              updateTransferOrder: {
                transfer_order_id: id,
                transfer_item_entry_id: itemsEntryId
              }
            }
          };

          await publishClient.publishEvent(detectorId, sensorProfileIds, context);
        }
      }

      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
      setStatusMessage(`The items have been verified.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_SUCCESSFUL, { action: actionsMap[actionId].actionType });
    } catch (e) {
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      setStatusMessage(`Failed to verify the items. Please contact Xemelgo Support for assistance.`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_FAILED, {
        action: actionsMap[actionId].actionType,
        error: JSON.stringify(e)
      });
    }

    resetState();
  };

  const transferOrdersSummary = useMemo(() => {
    return transferOrderEvents
      .map((transferOrderEvent) => {
        const { itemTypeEvents, identifier } = transferOrderEvent;
        const { expectedCount, scannedCount } = itemTypeEvents.reduce(
          (acc, itemTypeReport) => {
            acc.expectedCount += itemTypeReport.expectedCount;
            acc.scannedCount += itemTypeReport.scannedCount;
            return acc;
          },
          { expectedCount: 0, scannedCount: 0 }
        );

        return {
          identifier,
          scannedCount,
          expectedCount
        };
      })
      .sort((a, b) => {
        return a.identifier.localeCompare(b.identifier);
      });
  }, [transferOrders, itemTypeToReport]);

  return (
    <Modal
      title={actionsMap[actionId]?.label}
      onCancel={onClose}
      onConfirm={onConfirm}
      confirmDisabled={submitStatus === STATUS_OPTIONS.LOADING}
    >
      <div>
        <div>
          <p className={Style.header_text}>{transferOrderDisplayName}</p>
          {panelValues.transferOrdersString}
        </div>
        <div className={Style.summary_container}>
          <p className={Style.header_text}>Summary</p>
          {transferOrdersSummary.map((transferOrder) => {
            const { identifier, scannedCount, expectedCount } = transferOrder;
            return (
              <div
                key={identifier}
                className={Style.summary_table_row}
              >
                <div>
                  <p className={Style.main_row_text}>{identifier}</p>
                  <div className={Style.row}>
                    Status:
                    <Status
                      statusList={[
                        {
                          label: STAGE_TO_STATUS_MAP[panelValues.stage],
                          backgroundColor: xemelgoStyle.theme.APP_BLUE
                        }
                      ]}
                    />
                  </div>
                </div>
                <p className={Style.secondary_text}>{`${scannedCount}/${expectedCount} items scanned`}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

SubmissionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  transferOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemTypeToReport: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      identifier: PropTypes.string.isRequired,
      epcs: PropTypes.arrayOf(PropTypes.string).isRequired,
      quantityTotal: PropTypes.number.isRequired,
      quantityScanned: PropTypes.number.isRequired
    })
  ).isRequired
};
