import React, { useState, useContext } from "react";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";
import { READ_STATUS_OPTIONS } from "../../data/constants";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";

const KioskStateContext = React.createContext();

const initialState = {
  loading: false,
  locationOptions: [],
  readerLocationOptionsMap: {},
  itemByTag: {},
  itemTypeReports: [],
  searchInput: "",
  panelValues: {},
  isSubmitting: false,
  submitStatus: STATUS_OPTIONS.NONE,
  statusMessage: "",
  optionTemplateMap: {},
  readerOptions: [],
  readStatus: READ_STATUS_OPTIONS.NOT_READING
};

export const useKioskStateContext = () => {
  return useContext(KioskStateContext);
};

export const KioskStateContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(initialState.loading);
  const [isSubmitting, setIsSubmitting] = useState(initialState.isSubmitting);
  const [submitStatus, setSubmitStatus] = useState(initialState.submitStatus);
  const [statusMessage, setStatusMessage] = useState(initialState.statusMessage);
  const [locationOptions, setLocationOptions] = useState(initialState.locationOptions);
  const [readerOptions, setReaderOptions] = useState(initialState.readerOptions);
  const [itemByTag, setItemByTag] = useState(initialState.itemByTag);
  const [itemTypeReports, setItemTypeReports] = useState(initialState.itemTypeReports);
  const [searchInput, setSearchInput] = useState(initialState.searchInput);
  const [panelValues, setPanelValues] = useState(initialState.panelValues);
  const [optionTemplateMap, setOptionTemplateMap] = useState(initialState.optionTemplateMap);
  const [readStatus, setReadStatus] = useState(initialState.readStatus);

  const { setActionId } = useKioskSearchParams();

  // Resets state variables that were related to a kiosk action
  const resetState = () => {
    setIsSubmitting(initialState.isSubmitting);
    setItemByTag(initialState.itemByTag);
    setItemTypeReports(initialState.itemTypeReports);
    setPanelValues(initialState.panelValues);
    setSearchInput(initialState.searchInput);
    setActionId();
  };

  return (
    <KioskStateContext.Provider
      value={{
        loading,
        setLoading,
        locationOptions,
        setLocationOptions,
        itemByTag,
        setItemByTag,
        itemTypeReports,
        setItemTypeReports,
        searchInput,
        setSearchInput,
        panelValues,
        setPanelValues,
        isSubmitting,
        setIsSubmitting,
        submitStatus,
        setSubmitStatus,
        statusMessage,
        setStatusMessage,
        optionTemplateMap,
        setOptionTemplateMap,
        readerOptions,
        setReaderOptions,
        readStatus,
        setReadStatus,
        resetState
      }}
    >
      {children}
    </KioskStateContext.Provider>
  );
};
