import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { LocalCacheService } from "../../../../services/local-cache-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import Style from "../../Kiosk.module.css";
import ItemTable from "./features/item-table";
import SidePanel from "./features/side-panel";
import { queryItemsFromBasicTags } from "../../utils/query-items-from-basic-tags/queryItemsFromBasicTags";
import { queryItemsFromParsedTags } from "../../utils/query-items-from-parsed-tag/queryItemsFromParsedTags";
import { ClearScheduledInterval, ScheduledSyncWorkflowInterval } from "../../utils/scheduled-sync-workflow-interval";
import { FilterBar } from "./components/filter-bar/FilterBar";
import TopPanel from "./features/top-panel";
import useKioskConfigContext from "../../contexts/kiosk-config-context";
import useKioskStateContext from "../../contexts/kiosk-state-context";
import useMountedReader from "../../../../hooks/use-mounted-reader";
import { READ_STATUS_OPTIONS } from "../../data/constants";
import useKioskSearchParams from "../../hooks/use-kiosk-search-params";

export const MainPage = ({ handleScannedItems, onClearReportRow, onClearReport, onClearItem }) => {
  const xemelgoClient = useXemelgoClient();

  const { actionId } = useKioskSearchParams();
  const intervalIdRef = useRef();
  const inactivityIntervalIdRef = useRef();
  const abortControllerRef = useRef(new AbortController());

  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [inventoryClient] = useState(xemelgoClient.getInventoryClient());
  const [itemTypeClient] = useState(xemelgoClient.getItemTypeClient());

  const {
    additionalQueryAttributes,
    queryFrequencyInSeconds,
    upcNumberOfCharactersToTrim,
    inactivityThresholdInMinutes,
    hasSgtinTags,
    updateReaderStateOnClear,
    enableSimpleTagGrouping,
    itemClassFilters,
    topPanelAttributes,
    sidePanelAttributes,
    defaultLocationIdentifierFilterBy,
    readSettings,
    autoStartScanning,
    edgeApiUrl,
    readMode,
    startStopReader,
    disableIngestion
  } = useKioskConfigContext();
  const {
    panelValues,
    setPanelValues,
    itemByTag,
    setItemByTag,
    searchInput,
    setSearchInput,
    setIsSubmitting,
    readStatus,
    setReadStatus,
    readerOptions,
    locationOptions
  } = useKioskStateContext();
  const { getReaderTagMap, startMountedReader, resumeMountedReader, pauseMountedReader, stopMountedReader } =
    useMountedReader(edgeApiUrl, readMode, startStopReader, disableIngestion);

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
      setReadStatus(READ_STATUS_OPTIONS.NOT_READING);
      ClearScheduledInterval(intervalIdRef.current);
      clearInterval(inactivityIntervalIdRef.current);
    };
  }, []);

  const isSubmitDisabled = useMemo(() => {
    const unfilledAttributes = [...topPanelAttributes, ...sidePanelAttributes].filter((attribute) => {
      return attribute.isRequired && !panelValues[attribute.id];
    });

    return Object.keys(itemByTag).length === 0 || unfilledAttributes.length > 0;
  }, [sidePanelAttributes, panelValues, itemByTag]);

  const itemByTagRef = useRef(itemByTag);
  itemByTagRef.current = itemByTag;

  const queryItemsFromTags = async (discoveredTagData) => {
    const { itemType: itemTypeAttributes } = additionalQueryAttributes;

    if (enableSimpleTagGrouping) {
      const firstSeenTimestamp = Date.now();
      return discoveredTagData.map((tag) => {
        return {
          vid: tag.Name,
          type: { identifier: tag.Name, id: tag.Name },
          firstSeenTimestamp
        };
      });
    }
    if (hasSgtinTags) {
      return queryItemsFromParsedTags(
        discoveredTagData,
        itemByTagRef.current,
        itemTypeClient,
        itemTypeAttributes,
        upcNumberOfCharactersToTrim
      );
    }
    return queryItemsFromBasicTags(discoveredTagData, inventoryClient, additionalQueryAttributes, itemClassFilters);
  };

  // get default location by identifier filter
  const defaultLocation = useMemo(() => {
    if (defaultLocationIdentifierFilterBy && locationOptions.length) {
      const [firstLocationMatched = {}] = locationOptions.filter(({ identifier: locationIdentifier }) => {
        return locationIdentifier?.includes(defaultLocationIdentifierFilterBy);
      });
      return firstLocationMatched;
    }
    return {};
  }, [defaultLocationIdentifierFilterBy, locationOptions]);

  useEffect(() => {
    if (actionId) {
      const newPanelValues = { ...panelValues };

      if (readerOptions.length === 1) {
        const newReader = readerOptions[0];
        newPanelValues.readerLocation = newReader;
      }

      if (defaultLocation && Object.keys(defaultLocation).length) {
        newPanelValues.location = defaultLocation;
      }

      const cachedLocation = LocalCacheService.getCheckOutTableSelectedLocation();
      if (cachedLocation && Object.keys(cachedLocation).length > 0) {
        newPanelValues.location = cachedLocation;
      }

      setPanelValues(newPanelValues);
    } else {
      setPanelValues({});
    }
  }, [actionId, readerOptions, defaultLocation]);

  const onReadStatusUpdate = async (currentReadStatus, defaultDetectorSerial) => {
    const detectorSerial = defaultDetectorSerial || panelValues.readerLocation.detectorSerial;

    if (!detectorSerial) {
      return;
    }

    const statusOperationMap = {
      [READ_STATUS_OPTIONS.NOT_READING.id]: {
        startStatus: READ_STATUS_OPTIONS.STARTING,
        endStatus: READ_STATUS_OPTIONS.IN_PROGRESS,
        operation: startMountedReader
      },
      [READ_STATUS_OPTIONS.PAUSED.id]: {
        startStatus: READ_STATUS_OPTIONS.STARTING,
        endStatus: READ_STATUS_OPTIONS.IN_PROGRESS,
        operation: resumeMountedReader
      },
      [READ_STATUS_OPTIONS.IN_PROGRESS.id]: {
        startStatus: READ_STATUS_OPTIONS.PAUSING,
        endStatus: READ_STATUS_OPTIONS.PAUSED,
        operation: pauseMountedReader
      },
      [READ_STATUS_OPTIONS.STOPPING.id]: {
        startStatus: READ_STATUS_OPTIONS.STOPPING,
        endStatus: READ_STATUS_OPTIONS.NOT_READING,
        operation: stopMountedReader
      }
    };

    if (!statusOperationMap[currentReadStatus.id]) {
      return;
    }

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    const { startStatus, endStatus, operation } = statusOperationMap[currentReadStatus.id];

    setReadStatus(startStatus);

    try {
      await operation(detectorSerial, abortControllerRef.current.signal, readSettings);
      setReadStatus(endStatus);
    } catch (e) {
      if (e.name === "AbortError") {
        return;
      }

      setReadStatus(READ_STATUS_OPTIONS.NOT_READING);
    }
  };

  useEffect(() => {
    if (!panelValues?.readerLocation?.detectorSerial) {
      return;
    }

    if (readStatus.id === READ_STATUS_OPTIONS.IN_PROGRESS.id) {
      startWorkflow();
    } else if (autoStartScanning) {
      onReadStatusUpdate(READ_STATUS_OPTIONS.NOT_READING);
    } else {
      ClearScheduledInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
    }
  }, [panelValues, autoStartScanning, readStatus]);

  useEffect(() => {
    if (showInactivityModal) {
      ClearScheduledInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
      onReadStatusUpdate(READ_STATUS_OPTIONS.STOPPING);
    }
  }, [showInactivityModal]);

  const startWorkflow = async () => {
    const { detectorSerial } = panelValues.readerLocation;

    ClearScheduledInterval(intervalIdRef.current);
    intervalIdRef.current = ScheduledSyncWorkflowInterval(async () => {
      const alreadyQueriedTags = { ...itemByTagRef.current };
      const allTags = getReaderTagMap(detectorSerial, true);

      const discoveredTags = Object.values(allTags).filter((tagObject) => {
        return !alreadyQueriedTags[tagObject.Name];
      });

      const newItemByTag = await queryItemsFromTags(discoveredTags);

      if (Object.keys(newItemByTag).length) {
        const newItemByTagMap = {
          ...newItemByTag,
          ...(itemByTagRef?.current || {})
        };
        setItemByTag(newItemByTagMap);
        handleScannedItems(newItemByTag);
      }
    }, 1000 * queryFrequencyInSeconds);

    if (inactivityThresholdInMinutes > 0) {
      clearInterval(inactivityIntervalIdRef.current);
      inactivityIntervalIdRef.current = setInterval(() => {
        if (intervalIdRef.current) {
          setShowInactivityModal(true);
        }
      }, 60000 * inactivityThresholdInMinutes);
    }
  };

  return (
    <>
      <div className={Style.body}>
        <div className={Style.main_content_container}>
          <TopPanel
            onReadStatusUpdate={onReadStatusUpdate}
            onClear={() => {
              setItemByTag({});
              setShowInactivityModal(false);
              onClearReport();

              if (updateReaderStateOnClear) {
                onReadStatusUpdate(READ_STATUS_OPTIONS.NOT_READING);
              }
            }}
          />
          <FilterBar
            searchInput={searchInput}
            onFilter={setSearchInput}
          />
          <ItemTable
            onClearRow={onClearReportRow}
            onClearItem={onClearItem}
          />
        </div>
        {sidePanelAttributes?.length > 0 && <SidePanel />}
      </div>
      <div className={Style.footer}>
        <button
          type="button"
          className={Style.footer_button}
          disabled={isSubmitDisabled}
          onClick={() => {
            setIsSubmitting(true);
            setReadStatus(READ_STATUS_OPTIONS.PAUSED);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

MainPage.defaultProps = {
  handleScannedItems: () => {},
  onClearReportRow: () => {},
  onClearItem: () => {},
  onClearReport: () => {}
};

MainPage.propTypes = {
  handleScannedItems: PropTypes.func,
  onClearItem: PropTypes.func,
  onClearReportRow: PropTypes.func,
  onClearReport: PropTypes.func
};
