import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Style from "./PaginatedListTableWithTypes.module.css";
import useSortableHeader from "../../hooks/use-sortable-header";
import PaginatedList from "../PaginatedList/PaginatedList";
import OptionalLink from "../optional-link/OptionalLink";
import { SORT_DIRECTION } from "../../data/constants";
import { getValueByType } from "./utils/get-value-by-type";
import { OPTIONS_BUTTON_HEADER_ID, VALUE_TYPE_MAP } from "./data/constants";
import OptionsTooltip from "./components/options-tooltip";

export const PaginatedListTableWithTypes = ({
  headers,
  dataList,
  getTitleURLCallbackFn,
  isLoading,
  sortIdReplacementMap,
  columnSortDisabled,
  renderHeader,
  renderItem,
  headerRowContainerClassName,
  headerCellContainerClassName,
  itemCellContainerClassName,
  itemRowContainerClassName,
  emptyListContainerClassName,
  selectable,
  showRowOptions,
  onRowOptionClick,
  ...restProps
}) => {
  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    if (columnSortDisabled) {
      return {};
    }

    const defaultHeader =
      headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [headers, columnSortDisabled]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(
    defaultOrderDirection,
    defaultOrderBy,
    headers
  );

  const sortedDataList = useMemo(() => {
    return columnSortDisabled ? dataList : getSortedData(dataList, sortIdReplacementMap[orderBy]);
  }, [dataList, order, orderBy, sortIdReplacementMap, columnSortDisabled]);

  const LoadingComponent = () => {
    const numColumns = headers?.length || 3;
    return (
      <div
        className={`${Style.flex_row} ${
          selectable ? Style.table_item_selectable : Style.table_item
        } ${itemRowContainerClassName}`}
      >
        {[...Array(numColumns)].map((_, index) => {
          return (
            <div
              key={index}
              className={`${Style.flex_row} ${Style.table_column} ${itemCellContainerClassName}`}
            >
              <Skeleton
                containerClassName={Style.loading_icon}
                height={24}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <PaginatedList
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      selectable={selectable}
      data={sortedDataList}
      header={showRowOptions ? [...headers, { id: OPTIONS_BUTTON_HEADER_ID }] : headers}
      headerContainerClassName={`${Style.table_header} ${headerRowContainerClassName}`}
      renderEmptyList={() => {
        if (isLoading) {
          return [...Array(restProps.numItemsPerPage || 10)].map((_, index) => {
            return <LoadingComponent key={index} />;
          });
        }
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container} ${emptyListContainerClassName}`}>
            <p className={Style.empty_list_text}>No data available</p>
          </div>
        );
      }}
      renderHeader={
        renderHeader ||
        ((eachHeader) => {
          if (eachHeader.id === OPTIONS_BUTTON_HEADER_ID) {
            return (
              <div
                key={OPTIONS_BUTTON_HEADER_ID}
                className={Style.option_icon_container}
              />
            );
          }
          return getSortableHeader(eachHeader, {
            headerContainerClass: `${Style.flex_row} ${Style.table_column} ${headerCellContainerClassName} ${
              eachHeader.type === VALUE_TYPE_MAP.comment && Style.comment_column
            }`,
            className: Style.table_header_text,
            disabled: columnSortDisabled
          });
        })
      }
      renderItem={(eachItem) => {
        if (isLoading) {
          return <LoadingComponent />;
        }

        if (renderItem) {
          return renderItem(eachItem);
        }

        const { id, identifier, statusAccentColor, rowOptions } = eachItem;

        return (
          <div
            key={id || identifier}
            className={`${Style.flex_row} ${
              selectable ? Style.table_item_selectable : Style.table_item
            } ${itemRowContainerClassName}`}
            style={{
              borderLeftColor: statusAccentColor
            }}
          >
            {headers.map((eachHeader, subIndex) => {
              const { id: headerId, openLinkInNewTab, type, timeFormat, isNavLink } = eachHeader;

              return (
                <OptionalLink
                  key={`${eachItem[headerId]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column} ${itemCellContainerClassName} ${
                    type === VALUE_TYPE_MAP.comment && Style.comment_column
                  }`}
                  active={!!isNavLink}
                  target={openLinkInNewTab ? "_blank" : ""}
                  route={getTitleURLCallbackFn(eachItem, eachHeader.id)}
                >
                  <div className={`${Style.table_item_text} ${isNavLink && Style.table_item_text_clickable}`}>
                    {getValueByType(eachItem[headerId], type, timeFormat)}
                  </div>
                </OptionalLink>
              );
            })}
            {showRowOptions && rowOptions?.length > 0 && (
              <OptionsTooltip
                item={eachItem}
                rowOptions={rowOptions}
                onRowOptionClick={onRowOptionClick}
              />
            )}
          </div>
        );
      }}
    />
  );
};

PaginatedListTableWithTypes.defaultProps = {
  dataList: [],
  headers: [],
  getTitleURLCallbackFn: () => {
    return "";
  },
  isLoading: false,
  sortIdReplacementMap: {},
  columnSortDisabled: false,
  renderHeader: null,
  renderItem: null,
  headerRowContainerClassName: "",
  headerCellContainerClassName: "",
  itemCellContainerClassName: "",
  itemRowContainerClassName: "",
  emptyListContainerClassName: "",
  selectable: false,
  showRowOptions: false,
  onRowOptionClick: () => {}
};

PaginatedListTableWithTypes.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  headers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  getTitleURLCallbackFn: PropTypes.func,
  isLoading: PropTypes.bool,
  sortIdReplacementMap: PropTypes.object,
  columnSortDisabled: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  headerRowContainerClassName: PropTypes.string,
  headerCellContainerClassName: PropTypes.string,
  itemCellContainerClassName: PropTypes.string,
  itemRowContainerClassName: PropTypes.string,
  emptyListContainerClassName: PropTypes.string,
  selectable: PropTypes.bool,
  showRowOptions: PropTypes.bool,
  onRowOptionClick: PropTypes.func
};
