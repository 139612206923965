import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import { getStackedXemelgoLogo } from "../../common/Utilities";
import CheckBoxGroup from "../add-page-component/add-page-inputs/CheckBoxGroup";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import DateTimePicker, { DatePicker } from "../DateTimePicker/DateTimePicker";
import Style from "./MultiInputForm.module.css";
import Selector from "../selector/Selector";
import { AddPageInputTypeMap } from "../../data/constants";
import formatText from "../../utils/format-text";
import MultiSelectDropown from "../multi-select-dropdown";

const MultiInputForm = ({
  formFields,
  onChange,
  isLoading,
  isFormDisabled,
  containerClassName,
  fieldContainerClassName,
  loadingInputClassName,
  loadingTextClassName
}) => {
  const defaultXemelgoLogo = getStackedXemelgoLogo("dark");

  /**
   * This function covert a field value from the single form data or CSV data to an object that can
   * be use in a corresponding component
   * @param {any} newValue
   * @returns
   */
  const getFormattedFieldValue = (newValue) => {
    return typeof newValue === "string" && newValue
      ? { label: newValue }
      : newValue?.label
      ? { label: newValue?.label }
      : {};
  };

  const inputTypeControl = (field) => {
    const {
      type,
      value,
      isDisabled = isFormDisabled,
      isReadOnly,
      errorMessage,
      timeFormat,
      options,
      searchFn,
      textFormatOptions,
      placeholder,
      optionName
    } = field;

    switch (type) {
      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        return (
          <CheckBoxGroup
            value={{ ...value, disabled: isReadOnly || isDisabled }}
            options={options}
            onChange={(_, newValue) => {
              onChange(field.id, newValue);
            }}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
      case AddPageInputTypeMap.DATE_TIME_PICKER:
        if (isDisabled || isReadOnly) {
          return (
            <AutoSizeTextArea
              value={moment(value).format(timeFormat || "MM/DD/yyyy hh:mm a")}
              disabled={isDisabled}
              readOnly={isReadOnly}
              backgroundColor={isReadOnly ? "transparent" : ""}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          );
        }
        return (
          <DateTimePicker
            format={timeFormat}
            value={value}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onTimeChange={(newTime) => {
              onChange(field.id, newTime);
            }}
          />
        );
      case AddPageInputTypeMap.DATE_PICKER:
        if (isDisabled || isReadOnly) {
          return (
            <AutoSizeTextArea
              value={moment(value).format(timeFormat || "MM/DD/yyyy")}
              disabled={isDisabled}
              readOnly={isReadOnly}
              backgroundColor={isReadOnly ? "transparent" : ""}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          );
        }
        return (
          <DatePicker
            format={timeFormat}
            value={value}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onTimeChange={(newTime) => {
              onChange(field.id, newTime);
            }}
          />
        );
      case AddPageInputTypeMap.COMMENT:
        return (
          <AutoSizeTextArea
            maxRows={4}
            minRows={4}
            newLineAllowed
            value={formatText(value, textFormatOptions)}
            onChangeText={(newText) => {
              onChange(field.id, newText);
            }}
            disabled={isDisabled}
            readOnly={isReadOnly}
            backgroundColor={isReadOnly ? "transparent" : ""}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
        return (
          <SearchDropdown
            selectedItem={getFormattedFieldValue(value)}
            options={options}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onItemSelected={(newItem) => {
              onChange(field.id, newItem);
            }}
            inputBackgroundColor={isReadOnly ? "transparent" : ""}
            showIcon
          />
        );
      case AddPageInputTypeMap.SEARCH:
        return (
          <Selector
            options={options}
            onSelect={(item) => {
              onChange(field.id, item);
            }}
            searchFn={searchFn}
            value={getFormattedFieldValue(value)}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
      case AddPageInputTypeMap.MULTI_SELECT_DROP_DOWN:
        return (
          <MultiSelectDropown
            options={options}
            selectedOptions={value}
            disabled={isDisabled}
            placeholder={placeholder}
            optionName={optionName}
            onOptionClick={(newSelectedOptions) => {
              onChange(field.id, newSelectedOptions);
            }}
          />
        );
      case AddPageInputTypeMap.INPUT:
      default:
        const { numberOnly } = field;
        return (
          <AutoSizeTextArea
            value={formatText(value, textFormatOptions)}
            onChangeText={(newText) => {
              onChange(field.id, newText);
            }}
            numberOnly={numberOnly}
            disabled={isDisabled}
            readOnly={isReadOnly}
            backgroundColor={isReadOnly ? "transparent" : ""}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
    }
  };

  /**
   * Display sub-fields along with the image under the main field
   * @param {*} fieldsMap
   * @returns
   */
  const renderSubFields = (fieldsMap) => {
    const clonedFieldsMap = _.cloneDeep(fieldsMap);
    const { image_path: imagePath } = clonedFieldsMap;
    const { value: imageUrl } = imagePath || {};
    if (imagePath) {
      delete clonedFieldsMap.image_path;
    }

    return (
      <div className={Style.extra_info_container}>
        <div className={Style.left_container}>
          <img
            alt="item-type"
            className={Style.item_type_image}
            src={imageUrl || defaultXemelgoLogo}
          />
        </div>
        <div className={Style.right_container}>
          {Object.values(clonedFieldsMap).map((field) => {
            const { label, value } = field;
            if (label && value) {
              return (
                <div
                  key={label}
                  className={Style.subfields_container}
                >
                  <p className={Style.subfields_text_bold}>{`${label}:`}</p>
                  <p className={Style.subfields_text}>{value}</p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    const itemSkeletons = [];
    for (let i = 0; i < 3; i++) {
      itemSkeletons.push(
        <div
          key={`skeleton-${i}`}
          className={fieldContainerClassName}
        >
          <Skeleton className={`${Style.loading_input_text} ${loadingTextClassName}`} />
          <Skeleton className={`${Style.loading_input} ${loadingInputClassName}`} />
        </div>
      );
    }

    return <div className={`${Style.form_section_container} ${containerClassName}`}>{itemSkeletons}</div>;
  };

  const renderField = (field) => {
    if (field.isHidden) {
      return null;
    }

    const { subFieldsMap = {}, value } = field;

    return (
      <div
        key={`${field.id}-${field.type}`}
        className={fieldContainerClassName}
      >
        <div className={Style.input_text_container}>
          <p className={Style.input_title}>{field.label}</p>
          {field.isRequired && <p className={Style.required_asterisk}>*</p>}
        </div>
        <div
          data-cy={`multi-input-form-field-${field.id}`}
          className={Style.input_container}
        >
          {inputTypeControl(field)}
        </div>
        {value &&
          Object.keys(value).length > 0 &&
          Object.keys(subFieldsMap).length > 0 &&
          renderSubFields(subFieldsMap)}
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className={`${Style.form_section_container} ${containerClassName}`}>
      {formFields
        .sort((a, b) => {
          return a.index - b.index;
        })
        .map((field) => {
          return renderField(field);
        })}
    </div>
  );
};

MultiInputForm.defaultProps = {
  formFields: [],
  onChange: () => {},
  isLoading: false,
  containerClassName: "",
  fieldContainerClassName: "",
  isFormDisabled: false,
  loadingInputClassName: "",
  loadingTextClassName: ""
};

MultiInputForm.propTypes = {
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, value: PropTypes.any }),
        PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, value: PropTypes.any }))
      ]),
      type: PropTypes.oneOf(Object.values(AddPageInputTypeMap)),
      isHidden: PropTypes.bool,
      isReadOnly: PropTypes.bool,
      isRequired: PropTypes.bool,
      textFormatOptions: PropTypes.arrayOf(PropTypes.string),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string
        })
      ),
      numberOnly: PropTypes.bool,
      subFieldsMap: PropTypes.objectOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string
        })
      ),
      searchFn: PropTypes.func
    })
  ),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  containerClassName: PropTypes.string,
  fieldContainerClassName: PropTypes.string,
  isFormDisabled: PropTypes.bool,
  loadingInputClassName: PropTypes.string,
  loadingTextClassName: PropTypes.string
};

export default MultiInputForm;
