/**
 * @typedef OfType
 * @property { string } id
 * @property { string } identifier
 * @property { string } description
 */

/**
 * @typedef TrackItem
 * @property { string } id
 * @property { OfType[] } ofType
 */

/**
 * @typedef AssociatedWithTrackingSession
 * @property { string } id
 * @property { TrackItem[] } tracksItem
 */

/**
 * @typedef InventoryVerificationData
 * @property { string } vid
 * @property { AssociatedWithTrackingSession[] } associatedWithTrackingSession
 */

/**
 * @typedef ItemTypeReport { OfType}
 * @property { string[] } items
 * @property { string } id
 * @property { string } identifier
 * @property { string } description
 */

/**
 * @typedef ReducedItem
 * @property { string } vid
 * @property { string } itemId
 * @property { OfType } type
 */

/**
 *
 * @param items { ReducedItem[] }
 * @return { Object.<string, ItemTypeReport> }
 */
const generateItemTypeReport = (items) => {
  return items.reduce((map, item) => {
    const { type, firstSeenTimestamp } = item;
    const clonedMap = { ...map };
    const itemTypeReport = clonedMap[type.id] || {
      ...type,
      epcs: [],
      lastUpdatedTimestamp: 0
    };

    itemTypeReport.epcs.push(item.vid);
    itemTypeReport.lastUpdatedTimestamp =
      itemTypeReport.lastUpdatedTimestamp < firstSeenTimestamp
        ? firstSeenTimestamp
        : itemTypeReport.lastUpdatedTimestamp;
    clonedMap[type.id] = itemTypeReport;
    return clonedMap;
  }, {});
};

/**
 *
 * @param { ReducedItem[] } items
 * @return {ItemTypeReport[] }
 */
export const analyzeItemsForItemTypeReport = (items) => {
  const itemTypeReportMap = generateItemTypeReport(items);
  /**
   * @type {ItemTypeReport[]}
   */
  const sorted = Object.values(itemTypeReportMap).sort((a, b) => {
    return a.lastUpdatedTimestamp - b.lastUpdatedTimestamp;
  });
  return sorted;
};
